import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const SocialPartners = ({descr=''}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
            filter: {
              extension: { regex: "/(jpg)|(png)|(jpeg)/" }
              relativeDirectory: { eq: "media" }
            }
          )
          {
            nodes {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
              id
            }
          }
    }
  `)

  return (
      <div className="socpartners" id="partners">
        <div className="socpartners__container container">
            <h2 className="socpartners__title">
              Media Partners
            </h2>
            <p className="socpartners__subtitle">{descr}</p>
            <div className="socpartners__row row">
              {data.allFile.nodes.map(image => (
                <div className="socpartners__col col-md-2 col-3" key={image.id}>
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt=""
                  />
                </div>
              ))}
            </div>
        </div>
      </div>
  )
}

export default SocialPartners