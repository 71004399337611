import React, { useRef,useEffect, useState, useMemo } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/header";
import Footer from "../components/footer";
import Form from "../components/form";
import { Link } from "gatsby";
import SocPartners from "../components/socialPartners"
import Testimonials from "../components/testimonials"

const useHover = () => {
  const [hovered, setHovered] = useState();
  
  const eventHandlers = useMemo(() => ({
    onMouseOver() { setHovered(true); },
    onMouseOut() { setHovered(false); }
  }), []);
  
  return [hovered, eventHandlers];
}

const IndexPage = () => {
  const [hovered, eventHandlers] = useHover();
  
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <Layout classmain="homepage">
      <Seo
        title='Welcome to Bercastle'
        metaDesciption=''
      />
      <Header/>
      <div className='homepage__hero-container container'>
        <div className='homepage__hero row'>
          <div className='intro col-md-5 col-12'>
            <div className='intro__inner'>
              <h1 className='intro__inner--title'>
                Media Technology and Voice solutions
              </h1>
              <p className='intro__inner--p'>Robust, fast engineering multiplied by Marketing</p>
              <a href="#contact" className='btn'>Contact us</a>
            </div>
          </div>
          <div className={hovered? 'home hovered col-md-7 col-12' : 'home col-md-7 col-12'} onClick={()=> window.open("#services", "_self")}>
            <div {...eventHandlers} className="home__item home__item--v1">
              <h2 className='home__item--title'>Media</h2>
              <div className="home__inner--container">
                <div className="home__inner">
                  <h2>Media</h2>
                  <p>Bercastle is a premier independent agency founded on a foundation of expertise and passion. Our tight-knit team of talented professionals is committed to delivering impactful advertising solutions. Our ultimate objective is to drive exceptional campaign outcomes and foster sustained growth for our valued clients.</p>
                </div>
              </div>
            </div>
            <div {...eventHandlers} className="home__item home__item--v2">
              <h2 className='home__item--title'>Technology</h2>
              <div className="home__inner--container">
                <div className="home__inner">
                  <h2>Technology</h2>
                  <p>Unlock the full potential of your technology with our professional technical consulting services. Our experienced team of experts offers customized solutions to address your unique requirements and propel your business forward. Our specialists possess extensive knowledge in the areas of performance marketing, telecommunications, and artificial intelligence.</p>
                </div>
              </div>
            </div>
            <div {...eventHandlers} className="home__item home__item--v3">
              <h2 className='home__item--title'>Voice</h2>
              <div className="home__inner--container">
                <div className="home__inner">
                  <h2>Voice</h2>
                  <p>Our seasoned team boasts over a decade of experience in delivering scalable and robust technology solutions within the telecommunications industry. Our offerings encompass technology, BPO call-center services, quality control, and process assistance. Contact us to explore how we can help you tackle your next voice challenge.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='services-out' id="services">
        <div className='services container'>
          <h2 className='services__title'>Our Services</h2>
          <p className='services__subtitle'>Trust Bercastle to design and enhance your campaigns</p>
          <div className='services__inner row'>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <h3>Development</h3>
                <p>At Bercastle, we provide a comprehensive suite of product development services, including Business Analysis, Design, and Development, all under one roof.</p>
              </div>
            </div>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <h3>Digital Marketing</h3>
                <p>At Bercastle, we offer fast-track development of customized inbound and outbound marketing campaigns, designed to be ready for launch at a moment's notice.</p>
              </div>
            </div>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <h3>Artificial Intelligence</h3>
                <p>Transform your business operations with Bercastle's artificial intelligence solutions. Our team of experts leverages cutting-edge AI technology to automate processes, optimize performance, and provide real-time insights that drive smarter decision-making. Empower your business to reach new heights with AI.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SocPartners/>
      <div className='contact' id="contact">
        <div className='contact__container container'>
          <div className='contact__row row'>
            <div className='contact__col col-md-6 col-12'>
              <p className='contact__subtitle'>Enough small talk</p>
              <h2 className='contact__title'>Let's get to work</h2>
            </div>
            <div className='contact__col col-md-6 col-12'>
              <div className='contact__form'>
                <a className='contact__phone' href="tel:7276088386">
                  <img src='/images/phone.svg'/>
                  (727) 608-8386</a>
                <a className='contact__mail' href="mailto:hello@bercastle.com">hello@bercastle.com</a>
                <p className='contact_address'>
                  200 Central Ave, 4th Floor<br/>
                  Saint Petersburg, FL 33701, United States
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </Layout>
  )
}

export default IndexPage
